<template>
    <section id="cms-sidebar" :class="{menuCollapse : isCollapse}">
        <div class="sidebar-header">
            <h1 class="sidebar-title">BMS</h1>
            <Icon icon="hamburger" class="header-hamburger" @click="toggleCollapse()"/>
        </div>
        <div class="menu">
            <template v-for="(item, index) in menuItem" :key="index">
                <div :class="`${item.isActive ? 'active' : ''}`" class="menu-item" v-if="item.permission">
                    <div class="menu-category" @click="toggleMenu(index)">
                        <Icon
                            icon="dropdownArrow"
                            class="menu-dropdown-icon"
                            v-if="item.subMenu !== null"
                        />
                        <div class="menu-spacing" v-else></div>
                        <Icon :icon="item.icon" class="menu-icon" />
                        <h4 class="menu-title">{{ item.title }}</h4>
                    </div>
                    <div
                        class="menu-subItem"
                        :style="{ maxHeight: item.isActive ? item.subMenuHeight : '0px' }"
                        :ref="subMenuRefs[index]"
                    >
                        <template v-for="(subItem, subIndex) in item.subMenu" :key="subIndex">
                            <RouterLink
                                :to="subItem.to"
                                :class="`${subItem.isActive ? 'subItemActive' : ''} subItem-btn`"
                                @click="activateSubItem(index, subIndex)"
                                v-if="subItem.permission"
                                >{{ subItem.title }}</RouterLink
                            >
                        </template>
                    </div>
                </div>
            </template>
            <div class="menu-item">
                <div class="menu-category" @click="logout()">
                    <div class="menu-spacing"></div>
                    <Icon icon="logout" class="menu-icon" />
                    <h4 class="menu-title">Log out</h4>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup>
import { ref, watch } from 'vue'
import Icon from '@/assets/images/icons/Icon.vue'
import { useUserStore } from '@/stores/user'
import { storeToRefs } from 'pinia'
import { USER_ROLE } from '@/utils/enum'

const props = defineProps({
    isHomePage: {
        type: Boolean,
        required: false,
    },
});

const userStore = useUserStore()
const { role } = storeToRefs(userStore)

const isCollapse = ref(false);

const menuItem = ref([
    {
        icon: 'dataCentre',
        title: 'Data Centre',
        permission: role.value !== USER_ROLE.EVENT,//true,
        isActive: false,
        subMenu: [
            {
                title: 'Non - Trade Data Centre',
                to: '/dashboard',
                permission: true,
                isActive: false,
            },
        ],
    },
    {
        icon: 'publicComponent',
        title: 'Public Component',
        permission: role.value === USER_ROLE.ADMIN,
        isActive: false,
        subMenu: [
            {
                title: 'Menu Listing',
                to: '/publicComponent/menu',
                permission: true,
                isActive: false,
            },
            {
                title: 'Social Media',
                to: '/publicComponent/socialMedia',
                permission: true,
                isActive: false,
            },
            {
                title: 'Compliance',
                to: '/publicComponent/compliance',
                permission: true,
                isActive: false,
            },
            // {
            //     title: 'Domain Setting',
            //     to: '',
            //     permission: true,
            //     isActive: false,
            // },
            {
                title: 'Download Link',
                to: '/publicComponent/dynamicDownloadLink',
                permission: true,
                isActive: false,
            },
        ],
    },
    {
        icon: 'marketComponent',
        title: 'Marketing Component',
        permission: role.value === USER_ROLE.ADMIN || role.value === USER_ROLE.EVENT,
        isActive: false,
        subMenu:[{
            to:'/marketcomponent/prize',
            title:'Event Prizes',
            permission: true,
            isActive: false,

        },{ 
            to:'/marketcomponent/link',
            title:'Link Shortener',
            permission: role.value === USER_ROLE.ADMIN,
            isActive: false
        }]
    },
    {
        icon: 'countryList',
        title: 'Country List Module',
        permission: role.value === USER_ROLE.ADMIN,
        isActive: false,
        subMenu: [
            {
                title: 'Country Listing',
                to: '/country/list',
                permission: true,
                isActive: false,
            },
            {
                title: 'Group Tag Setting',
                to: '/country/tag',
                permission: true,
                isActive: false,
            },
        ],
    },
    // {
    //     icon: 'bannerCarouselSetting',
    //     title: 'Banner Carousel Setting',
    //     permission: role.value === USER_ROLE.ADMIN,
    //     isActive: false,
    //     subMenu: [
    //         {
    //             title: 'Banner Upload',
    //             to: '',
    //             permission: true,
    //         },
    //         {
    //             title: 'Banner Setting',
    //             to: '',
    //             permission: true,
    //         },
    //     ],
    // },
    {
        icon: 'mail',
        title: 'Email',
        permission: role.value === USER_ROLE.ADMIN,
        isActive: false,
        subMenu: [
            // {
            //     title: 'Email Configuration',
            //     to: '/',
            //     permission: true,
            // },
            {
                title: 'Email Log',
                to: '/email/emailLogs',
                permission: true,
            },
        ],
    },
    {
        icon: 'configure',
        title: 'Setting',
        permission: role.value === USER_ROLE.ADMIN,
        isActive: false,
        subMenu: [
            {
                title: 'User',
                to: '/setting/user',
                permission: true,
                isActive: false,
            },
            // {
            //     title: 'Role Configurations',
            //     to: '/',
            //     permission: true,
            //     isActive: false,
            // },
            // {
            //     title: 'API Configs',
            //     to: '/setting/apiConfigs',
            //     permission: true,
            // },
            {
                title: 'System Log',
                to: '/setting/systemLogs',
                permission: true,
            },
        ],
    },
])

const subMenuRefs = menuItem.value.map(()=>ref(null))
const toggleCollapse = () => {
    if(!isCollapse.value)
        isCollapse.value = true;
    else
        isCollapse.value = false;
}

const toggleMenu = (id) => {
    menuItem.value.forEach((item, index) => {
        if (index === id) {
            item.isActive = !item.isActive
            const subMenuEl = subMenuRefs[index].value[0]
            item.subMenuHeight = item.isActive && !isCollapse.value ? `${subMenuEl.scrollHeight}px` : '0px'
        } else {
            item.isActive = false
            item.subMenuHeight = '0px'
        }
    })
}

const activeState = ref({
    menuId: null,
    subMenuId: null,
})

watch(() => {
    if (props.isHomePage) {
        activeState.value = {
            menuId: null,
            subMenuId: null,
        }

        toggleMenu(false)

        menuItem.value.forEach((item, index) => {
            if (item.subMenu) {
                item.subMenu.forEach((subItem, subIndex) => {
                    subItem.isActive = false
                })
            }
        })
    }
});

const activateSubItem = (menuId, subMenuId) => {
    if (activeState.value.menuId === menuId && activeState.value.subMenuId === subMenuId) {
        return
    }
    activeState.value = { menuId, subMenuId }
    menuItem.value.forEach((item, index) => {
        if (item.subMenu) {
            item.subMenu.forEach((subItem, subIndex) => {
                subItem.isActive = index === menuId && subIndex === subMenuId
            })
        }
    })
}

const logout = () => {
    userStore.logout()
}
</script>

<style lang="scss">
#cms-sidebar {
    transition: 0.3s ease-out;
    background-color: var(--sidebar-background-color);
    width: 310px;
    position: sticky;
    top: 0;

    @media screen and (max-width: 767px){
        height: 100%;
        position: absolute;
        z-index: 999;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        margin: 0;
    }

    h1 {
        font-weight: 700;
        font-size: 48px;
        color: var(--sidebar-text-color);
        line-height: normal;
    }

    h4 {
        font-weight: 400;
        font-size: 14px;
        color: var(--sidebar-text-color);
    }

    .sidebar-header {
        padding: 12px 12px 36px 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .sidebar-title{
            color: var(--white-100) !important;
            line-height: 1;
            margin-left: 12px;
        }

        .header-hamburger {
            cursor:pointer;
        }

        .header-hamburger svg {
            width: 18px;
            height: 18px;
        }

        .header-hamburger svg > path {
            fill: var(--sidebar-text-color);
        }
    }

    .menu {
        padding: 0 8px;
        background-color: var(--sidebar-background-color);

        .menu-item {
            border-radius: 8px;

            &:hover {
                cursor: pointer;
                background-color: var(--sidebar-hover-color);
            }

            .menu-spacing {
                margin: 0 14px;
            }

            .menu-category {
                display: flex;
                align-items: center;
                position: relative;
                gap: 8px;
                padding: 16px 0;

                .menu-dropdown-icon {
                    margin: 0 6px 0 12px;
                    transition: 0.3s;
                    transform: rotate(270deg);

                    svg {
                        width: 10px;
                        height: 10px;

                        path {
                            fill: var(--sidebar-text-color);
                        }
                    }
                }

                .menu-icon {
                    svg {
                        path {
                            fill: var(--sidebar-text-color);
                        }
                    }
                }

                .menu-title {
                    color: var(--sidebar-text-color);
                }
            }

            .menu-subItem {
                overflow: hidden;
                transition: max-height 0.3s ease-out;
                .subItem-btn {
                    font-size: 14px;
                    font-weight: 400;
                    text-decoration: none;
                    display: block;
                    padding: 12px 0 12px 40px;
                    color: var(--sidebar-text-color);
                }
            }
        }

        /* isActive */

        .menu-item.active {
            background-color: var(--sidebar-primary-color);
            border-radius: 8px;

            .menu-category {
                border-radius: 8px 8px 0 0;

                .menu-dropdown-icon {
                    transition: 0.3s;
                    transform: rotate(0deg);

                    svg {
                        path {
                            fill: var(--sidebar-active-text);
                        }
                    }
                }

                .menu-icon {
                    svg {
                        path {
                            fill: var(--sidebar-active-text);
                        }
                    }
                }

                .menu-title {
                    color: var(--sidebar-active-text);
                    font-weight: 600;
                }
            }

            .menu-subItem {
                background-color: var(--sidebar-secondary-color);
                border-radius: 8px;
                max-height: 0;
                overflow: hidden;
                transition: max-height 0.2s ease-out;

                .subItem-btn {
                    &:not(.subItemActive):hover {
                        transition: 0.3s;
                        background-color: var(--sidebar-hover-color);
                        border-radius: 8px;
                    }

                    color: var(--sidebar-active-text);
                }

                .subItemActive {
                    background-color: var(--sidebar-active-color);
                    color: var(--sidebar-active-text);
                    font-weight: 600;
                    border-radius: 8px;
                }
            }
        }
    }
    
}

#cms-sidebar.menuCollapse{
    transition: 0.3s ease-in;
    width: 48px;
    position: relative;

    @media screen and (max-width: 767px){
        height: unset;
        position: relative;
        z-index: unset;
    }

    .sidebar-header {
        padding: 24px 12px 36px 12px;
        .sidebar-title{
            display:none;
        }
    }

    .menu {
        padding: 0;
        background-color: var(--sidebar-background-color);

        .menu-item {
            border-radius: unset;

            .menu-spacing {
                display: none;
            }

            .menu-category {
                display: block;
                position: relative;

                .menu-dropdown-icon {
                    display: none;
                }

                .menu-icon {
                    svg {

                        margin:auto;

                        path {
                            fill: var(--sidebar-text-color);
                        }
                    }
                }

                .menu-title {
                    display:none;
                }
            }
        }

        /* isActive */

        .menu-item.active {
            border-radius: unset;

            .menu-category {
                border-radius: 8px 8px 0 0;

                .menu-dropdown-icon {
                    display:none;
                }

                .menu-icon {
                    svg {
                        path {
                            fill: var(--sidebar-active-text);
                        }
                    }
                }

                .menu-title {
                    color: var(--sidebar-active-text);
                    font-weight: 600;
                }
            }

            .menu-subItem {
                background-color: var(--sidebar-background-color);
                border-radius: 0 8px 8px 0;
                border: 1px solid black;
                max-height: unset !important;
                overflow: unset;
                transition: none;
                position: absolute;
                z-index: 999;
                top: 0;
                left: 100%;
                height: 100vh;

                .subItem-btn {
                    border-radius: 8px;
                    width: 200px;
                    padding: 14px 24px;
                    color: var(--sidebar-text-color);
                    background-color: var(--sidebar-background-color);

                    &:not(.subItemActive):hover {
                        transition: 0.3s;
                        background-color: var(--sidebar-hover-color);
                        border-radius: 8px;
                    }

                    color: var(--sidebar-active-text);
                }

                .subItemActive {
                    border-radius: 8px;
                    background-color: var(--sidebar-active-color);
                    color: var(--sidebar-active-text);
                    font-weight: 600;
                }
            }
        }
    }
    
}
</style>
