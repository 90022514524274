<template>
    <div class="modal-edit-financial-assets">
        <h2>Edit Financial Assets</h2>
        <div class="asset-edit-list-wrapper">
            <div class="asset-edit-list" v-for="(asset, index) in commonStore.dataFinancialAssets" :key="index">
                <div class="list-edit" v-if="editId === asset.id">
                    <input type="text" v-model="inputNameEdit" />
                    <div class="action-group">
                        <span class="btn-save text-info" @click="confirmEdit()">Save</span>
                        <span class="btn-cancel text-danger" @click="cancelEdit()">Cancel</span>
                    </div>
                </div>
                <div class="list-display" v-if="editId !== asset.id">
                    <span>{{ asset.name }}</span>
                    <CIcon class="icon btn-edit" icon="cil-pencil" height="18" alt="Edit"
                        @click="setEdit(asset.id, asset.name)" />
                    <CIcon class="icon btn-edit" icon="cil-trash" height="18" alt="Edit"
                        @click="handleDelete(asset.id, asset.name)" />
                </div>
            </div>
            <div class="input-group" v-if="!editId">
                <input class="input form-control" placeholder="Add New Financial Asset" type="text" name="name"
                    v-model="inputNameAdd" />
                <button :class="`btn-add ${inputNameAdd ? '' : 'disabled'}`" @click="handleInsertFinancialAsset()">
                    Add
                </button>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
.modal-edit-financial-assets {
    .asset-edit-list-wrapper {
        padding-top: 16px;

        .asset-edit-list {
            padding-bottom: 16px;

            .list-edit {
                display: flex;
                justify-content: space-between;

                .btn-save {
                    margin-right: 10px;
                    cursor: pointer;
                }

                .btn-cancel {
                    cursor: pointer;
                }
            }
        }

        .btn-edit {
            margin-left: 10px;
            cursor: pointer;
        }

        .input-group {
            margin-top: 10px;
            display: flex;
            justify-content: space-between;

            .btn-add {
                padding: 8px 12px;
                border-radius: 0 8px 8px 0 !important;
                border: 1px solid #dddddd;
                background-color: #ffffff;

                &.disabled {
                    pointer-events: none;
                    color: #666666;
                    background-color: #dddddd;
                }
            }
        }
    }
}
</style>

<script>
import { useCommonStore } from '@/stores/common'
import {
    findFinancialAssets,
    insertFinancialAsset,
    updateFinancialAsset,
    removeFinancialAsset,
} from '@/api/financialAssets'

export default {
    name: 'ModalEditFinancialAssets',
    setup() {
        const commonStore = useCommonStore()
        return {
            commonStore,
        }
    },
    methods: {
        async confirmEdit() {
            const data = await updateFinancialAsset(this.editId, { name: this.inputNameEdit })
            this.fetchFinancialAssets(this.$route.params.categoryId)
            this.inputNameEdit = null
            this.editId = null
        },
        cancelEdit() {
            this.inputNameEdit = null
            this.editId = null
        },
        setEdit(id, value) {
            this.inputNameEdit = value
            this.editId = id
        },
        async handleDelete(id, name) {
            if (confirm(`Are your sure to delete ${name}, this cannot be undone.`)) {
                await removeFinancialAsset(id)
                this.fetchFinancialAssets(this.$route.params.categoryId)
                this.$router.push({
                    name: 'FinancialAssetList',
                    params: {
                        entityId: this.$route.params.entityId,
                        categoryId: this.$route.params.categoryId,
                        dataType:
                            this.commonStore.activeCategory.name !== 'overnight interest' && this.commonStore.activeCategory.name !== 'islamic account'
                                ? 'assets'
                                : 'oi',
                    },
                })
            } else {
                //do nth
            }
        },
        async handleInsertFinancialAsset() {
            if (this.inputName == '') return
            const id = this.$route.params.categoryId
            let body = {
                name: this.inputNameAdd,
            }
            await insertFinancialAsset(id, body)
            await this.fetchFinancialAssets(id)
            this.inputNameAdd = ''
        },
        async fetchFinancialAssets(id) {
            const { data } = await findFinancialAssets(id)
            this.commonStore.setDataFinancialAssets(data)
        },
    },
    data() {
        return {
            editId: null,
            inputNameEdit: '',
            inputNameAdd: '',
        }
    },
}
</script>
